<template>
    <v-alert
        type="warning"
    >
        <v-row align="center">
            <v-col class="grow">
                Bekræft venligst din email for at fortsætte!
            </v-col>
            <v-col class="shrink">
                <v-btn
                    small
                    @click="ClickGetUserEmailConfirmationToken"
                >
                    Gensend email
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog
            v-model="dialog"
            width="300"
        >  
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Bekræft
                </v-card-title>
  
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                Der er sendt en bekræftelses-email  
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
  
                <v-divider />
  
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        @click="dialog = false"
                    >
                        Luk
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-alert>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            dialog: false
        }
    },
    computed: {
        ...mapGetters({
            getEmailConfirmed: 'getEmailConfirmed'
        })
    },
    created() {        
    },    
    methods: {
        ClickGetUserEmailConfirmationToken(){
            this.GetUserEmailConfirmationToken()
                .then(data => {                  
                    if(data.success){
                        return
                    }
                    return
                })

            this.dialog = true
        },
        ...mapActions({
            GetUserEmailConfirmationToken: 'GetUserEmailConfirmationToken'
        })
    }
}
</script>
